import ColorValue from "types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export default function SolanaExplorerIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43572 16.7254C5.50022 16.6545 5.57884 16.5978 5.66651 16.559C5.75418 16.5201 5.849 16.5001 5.94488 16.5L21.6553 16.5129C21.7222 16.513 21.7876 16.5325 21.8436 16.5692C21.8996 16.6058 21.9438 16.6579 21.9707 16.7191C21.9977 16.7803 22.0063 16.848 21.9955 16.9141C21.9847 16.9801 21.9549 17.0416 21.9098 17.091L18.5643 20.7746C18.4997 20.8456 18.4211 20.9023 18.3333 20.9411C18.2455 20.9799 18.1506 21 18.0547 21L2.34474 20.9871C2.27784 20.987 2.21241 20.9675 2.15641 20.9308C2.10041 20.8942 2.05625 20.8422 2.02929 20.7809C2.00233 20.7197 1.99373 20.652 2.00453 20.5859C2.01534 20.5199 2.04509 20.4584 2.09016 20.409L5.43572 16.7254ZM21.9098 13.659C21.9549 13.7084 21.9847 13.7699 21.9955 13.8359C22.0063 13.902 21.9977 13.9697 21.9707 14.0309C21.9438 14.0922 21.8996 14.1442 21.8436 14.1808C21.7876 14.2175 21.7222 14.237 21.6553 14.2371L5.94531 14.25C5.84937 14.25 5.75446 14.2299 5.6667 14.1911C5.57895 14.1523 5.50028 14.0956 5.43572 14.0246L2.09016 10.3389C2.04509 10.2894 2.01534 10.2279 2.00453 10.1619C1.99373 10.0959 2.00233 10.0282 2.02929 9.96694C2.05625 9.9057 2.10041 9.85363 2.15641 9.81703C2.21241 9.78041 2.27784 9.76084 2.34474 9.76071L18.0551 9.74786C18.151 9.74792 18.2458 9.768 18.3335 9.80683C18.4212 9.84564 18.4998 9.90234 18.5643 9.97329L21.9098 13.659ZM5.43572 3.22543C5.50022 3.15448 5.57884 3.09778 5.66651 3.05897C5.75418 3.02015 5.849 3.00006 5.94488 3L21.6553 3.01286C21.7222 3.01299 21.7876 3.03255 21.8436 3.06916C21.8996 3.10577 21.9438 3.15785 21.9707 3.21908C21.9977 3.28031 22.0063 3.34805 21.9955 3.41407C21.9847 3.48009 21.9549 3.54156 21.9098 3.591L18.5643 7.27457C18.4997 7.34556 18.4211 7.40231 18.3333 7.44111C18.2455 7.47994 18.1506 7.5 18.0547 7.5L2.34474 7.48714C2.27784 7.48701 2.21241 7.46745 2.15641 7.43083C2.10041 7.39423 2.05625 7.34216 2.02929 7.28091C2.00233 7.21969 1.99373 7.15196 2.00453 7.08594C2.01534 7.01991 2.04509 6.95844 2.09016 6.909L5.43572 3.22543Z"
        fill={colorValue}
      />
    </svg>
  );
}
